import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
// import { GtrEmailTrigger } from '@/interfaces/common.interface'

@Component({
  name: 'GtrEmailTriggerEditView',
  computed: {
    ...mapState('email', ['emails', 'emails_live'])
  }
})
export default class GtrEmailTriggerEditView extends GtrSuper {
  @Prop()
  trigger!: Record<string, any>;

  emails!: Record<string, any>;

  emails_live!: Record<string, any>;

  data () {
    return {
      loading: false,
      triggerData: {
        emails: []
      }
    }
  }

  @Watch('trigger', { immediate: true, deep: true })
  onTriggerChange (payload) {
    this.$data.triggerData = payload ? JSON.parse(JSON.stringify(payload)) : { emails: [] }
  }

  get triggerName (): string {
    return this.$data.triggerData?.default_data?.trigger_headline ?? ''
  }

  get emailSelectItems () {
    return this.emails_live?.map(email => {
      return {
        text: this.$options?.filters?.capitalizeAndRemoveUnderscores(email.key) ?? email.key,
        value: email.key
      }
    }) ?? []
  }

  async saveTrigger () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        trigger_uuid: this.$data.triggerData.uuid,
        data: this.$data.triggerData
      }
      await this.$store.dispatch('email/updateEmailTrigger', payload)
      Container.get(Notification).success('Email trigger successfully updated.')
      this.$emit('close')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
